import React from "react";
import {Col, Container, Nav, Row} from "react-bootstrap";
import Mailchimp from "react-mailchimp-form";

import styles from "./index.module.scss";
import OandaPoweredbyShiftLogo from "../../../img/OandaImages/Oanda-Logo-White.svg";
import facebookIcon from "../../../img/icon-facebook.svg";
import twitterIcon from "../../../img/icon-twitter.svg";
import instagramIcon from "../../../img/icon-instagram.svg";

class Footer extends React.Component {
    render() {
        return (
            <>
                <Container fluid={true} className={styles.footerFluidContainer}>
                    <Container as="footer" className={styles.footerFixedContainer}>
                        <Row>
                            <Col xs={12} lg={2} className={styles.shiftLogoColumn}>
                                <img src={OandaPoweredbyShiftLogo} width="168" height="48"
                                     alt="Oanda Powered By Shift"/>
                            </Col>
                            <Col xs={12} sm={12} lg={4} className={styles.addressColumn}>
                                <Row>
                                    <Col xs={12} sm={6} lg={12}>
                                        <address>
                      <span className={styles.addressCityCountry}>
                        Contact Us
                      </span>
                                            North America: 1-888-999-7068 <br/>
                                            Europe: 0800-029-3010 <br/>
                                            <a href="mailto://support@oandafxps.com">
                                                support@oandafxps.com
                                            </a>

                                        </address>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Row>
                                    <Col xs={12} sm={6} lg={4}>
                                        <Nav className="flex-column">
                                            <a href="https://www.oanda.com/group/about-us/" className={styles.navLink}>
                                                About Us
                                            </a>
                                            <a href="https://blog.oanda.com/" className={styles.navLink}>
                                                Blog
                                            </a>
                                        </Nav>
                                    </Col>
                                    <Col xs={12} sm={6} lg={4}>
                                        <Nav defaultActiveKey="/home" className="flex-column">
                                            <a href="https://www.oanda.com/foreign-exchange-data-services/en/faq-help-portal/fx-payments-faqs/"
                                               className={styles.navLink}>
                                                FAQ
                                            </a>
                                            <a href="https://oanda.paydirect.io/registrations/ib-uk-terms"
                                               className={styles.navLink}>
                                                Terms of Service and Privacy Policy
                                            </a>
                                        </Nav>
                                    </Col>
                                    <Col xs={12} lg={4} className={styles.accountLinks}>
                                        <Nav className="flex-column">
                                            <div className={styles.socialLinks}>
                                                <a href="https://www.facebook.com/FXDataServices">
                                                    <img
                                                        src={facebookIcon}
                                                        width="24"
                                                        height="24"
                                                        alt="Facebook Icon"
                                                    />
                                                </a>
                                                <a href="https://twitter.com/FXDataServices">
                                                    <img
                                                        src={twitterIcon}
                                                        width="24"
                                                        height="24"
                                                        alt="Twitter Icon"
                                                    />
                                                </a>
                                                <a href="https://www.instagram.com/oanda/">
                                                    <img
                                                        src={instagramIcon}
                                                        width="24"
                                                        height="24"
                                                        alt="Instagram Icon"
                                                    />
                                                </a>
                                            </div>
                                        </Nav>
                                    </Col>
                                    <Col xs={12} className={styles.newsletterColumn}>
                                        <h2 className={styles.newsletterHeading}>
                                            Market News Signup
                                        </h2>
                                        <Mailchimp
                                            action="https://shiftconnect.us8.list-manage.com/subscribe/post?u=71003fc4d41d5647c6728e424&amp;id=55df9920c3"
                                            fields={[
                                                {
                                                    name: "EMAIL",
                                                    placeholder: "Email",
                                                    type: "email",
                                                    required: true
                                                }
                                            ]}
                                            messages={{
                                                sending: "Sending...",
                                                success: "Thank you for subscribing!",
                                                error:
                                                    "An error has occurred. Please contact us at info@shiftconnect.com.",
                                                empty: "Please provide your e-mail address.",
                                                duplicate:
                                                    "Too many subscribe attempts for this email address.",
                                                button: "Subscribe"
                                            }}
                                            styles={{
                                                sendingMsg: {
                                                    color: "#f7f6fd"
                                                },
                                                successMsg: {
                                                    color: "#ecfef6"
                                                },
                                                duplicateMsg: {
                                                    color: "#fedf4a"
                                                },
                                                errorMsg: {
                                                    color: "#fedf4a"
                                                }
                                            }}
                                            className={styles.mailchimp}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <Container
                    fluid={true}
                    className={styles.footerCopyrightFluidContainer}
                >
                    <Container className={styles.footerCopyrightFixedContainer}>
                        <Row className={styles.footerCopyrightRow}>
                            <Col>
                                <p>
                                    For clients based in the United States, payment services for Shift Connect are provided by The Currency Cloud Inc. which operates in partnership with Community Federal Savings Bank (CFSB) to facilitate payments in all 50 states in the US. CFSB is registered with the Federal Deposit Insurance Corporation (FDIC Certificate# 57129). The Currency Cloud Inc is registered with FinCEN and authorised in 39 states to transmit money (MSB Registration Number: 31000206794359). Registered Office: 104 5th Avenue, 20th Floor, New York , NY 10011.
                                    <br/><br/>
                                    For clients based in the United Kingdom and rest of the world, payment services for Shift Connect are provided by The Currency Cloud Limited. Registered in England and Wales No. 06323311. Registered Office: Stewardship Building 1st Floor, 12 Steward Street London E1 6FQ. The Currency Cloud Limited is authorised by the Financial Conduct Authority under the Electronic Money Regulations 2011 for the issuing of electronic money (FRN: 900199).Ò
                                    <br/><br/>
                                    For clients based in the European Economic Area, payment services for Shift Connect are provided by CurrencyCloud B.V.. Registered in the Netherlands No. 72186178. Registered Office: Nieuwezijds Voorburgwal 296 - 298, Mindspace Nieuwezijds Office 001 Amsterdam. CurrencyCloud B.V. is authorised by the DNB under the Wet op het financieel toezicht to carry out the business of an electronic-money institution (Relation Number: R142701).
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </>
        );
    }
}

export default Footer;
