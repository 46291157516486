import React from "react";
import Button from "../../Button";
import bannerImg3 from "../../../img/OandaImages/hero-business-oanda-desktop.png";
import styles from "./index.module.scss";

const ThankyouOanda = (props) => {
    return (
        <div className="OandaBanner">
            <div className={"container"}>
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="banner-wrapper-content">
                            <span className={styles.subHeading}>{props.welcome}</span>
                            <h3 className={styles.oandaHeading}> {props.heading}  </h3>
                            <p className={styles.description}>{props.message} </p>
                            <div className={styles.ctaWrapper}>
                                <Button
                                    href={props.link}
                                    color={"oanda"}
                                    className={styles.getStartedButton}
                                >
                                    {props.buttonText}
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div>
                            <img src={bannerImg3} class="img-fluid" alt="banner"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThankyouOanda;