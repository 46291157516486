import React from "react";

import Layout from "../../components/OandaComponents/LayoutOanda";
import Background from "../../components/Background";
import OandaThankyouHeader from "../../components/OandaComponents/ThankyouOanda";







const OandaThankYouPage = () => {
    return (
      <Layout>
        <Background themeType={"oanda"}>
          <OandaThankyouHeader
              welcome = "Thank You"
              heading = "Your submission was successful."
              message = "We’ve received your request for an
                                    account with OANDA FX Payments powered by Shift Connect and our compliance team is
                                    working to get it approved as soon as possible. Look out for an email in the next 
                                    48-hours from our onboarding team. If you have any questions in the meantime, 
                                    schedule a meeting with a member of our team by clicking below."
              link =  "https://meetings.hubspot.com/ryan-gour/oanda-fx-payments"
              buttonText ="Schedule a Meeting"
          > 
          </OandaThankyouHeader>         
        </Background>
      </Layout>
    );
  };
  
  export default (OandaThankYouPage);