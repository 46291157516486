import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "gatsby";

import Button from "../../Button";

import styles from "./index.module.scss";

import OandaPoweredbyShift from "../../../img/OandaImages/Oanda-FX-Shift.svg";

class Navigation extends React.Component {
  render() {
    return (
      <Navbar expand="xl" sticky="top" className={styles.navbar}>
        <Container className={styles.navbarContainer}>
          <Link to="/" className={styles.brand}>
            <img src={OandaPoweredbyShift} width="178" height="72" alt="Oanda Powered By Shift Logo" />
          </Link>
          <div className={styles.buttonToggleWrapper}>
            {typeof window !== `undefined` &&
              window.location.pathname !== "/business" && (
                <Button
                  navButton={true}
                  href="/oanda-contact"
                  className={`${styles.navButton} ${styles.navButtonMobile}`}
                >
                  Contact Us
                </Button>
              )}
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className={styles.navbarToggler}
            />
          </div>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className={`${styles.navbarNav} mr-auto`}>
            </Nav>
            <Nav className={`${styles.navbarNav} ${styles.navbarNavSecondary}`}>
              {typeof window !== `undefined` &&
                window.location.pathname !== "/business" && (
                  <Button
                    navButton={true}
                    href="/oanda-contact"
                    className={`${styles.navButton} ${styles.navButtonDesktop}`}
                  >
                    Contact Us
                  </Button>
                )}
              <NavDropdown
                title="Login"
                className={`${styles.navLink} ${styles.loginLink}`}
              >
                <NavDropdown.Item
                  eventKey="Oanda-login"
                  href="https://oanda.paydirect.io/login"
                  bsPrefix={`dropdown-item ${styles.personalLogin}`}
                >
                  Sign In
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="Oanda-login"
                  href="https://onboarding.paydirect.io/oanda/forms/corporate"
                  bsPrefix={`dropdown-item ${styles.businessLogin}`}
                >
                  Sign Up
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default Navigation;